import React, { useState, useEffect, useContext } from 'react';
import { Menu, Image, Button, Icon, Sidebar, Dropdown } from 'semantic-ui-react';
import { useRouter } from 'next/router';

import { logout } from 'utils/auth';
import styles from './appBar.module.scss';
import { InvestorSignUp } from 'components/Sidebar/InvestorSignUp';
import { InvestorSignUpKeyPerson } from 'components/Sidebar/InvestorSignUpKeyPerson';
import { AppContext } from './../../../pages/_app';
import { InvestorSignUpJoinGroup } from 'components/Sidebar/InvestorSignUpJoinGroup';
import { get, post } from 'utils/apiClient';
import { first, isEmpty } from 'lodash';

/**
 * 
 * Desktop menu
 */


// Get sidebar signup type from local storage var, or default to SIGNUP_INVESTOR
const getSignupType = () => {
  if(window.localStorage.getItem('submitToken')) return 'SIGNUP_INVESTOR_KEY_PERSON';
  if(window.localStorage.getItem('joinGroupSignUpToken')) return 'SIGNUP_INVESTOR_JOIN_GROUP';
  return 'SIGNUP_INVESTOR';
};

function DesktopMenu(props) {
	const {handleSidebarConfigChange} = useContext(AppContext);
  const [accountType, setAccountType] = useState('');
  const [accessibleProducts, setAccessibleProducts] = useState([]);
  const [accessibleAccounts, setAcessibleAccounts] = useState([]);
  const [serverError, setServerError] = useState('');

  const router = useRouter();
  const [activeLink, setActiveLink] = useState('');
  useEffect(() => {
    if (router != null) {
      setActiveLink(router.asPath);
    }
  }, [router]);

  const getAccessibleMenuItems = async () => {
    setServerError();
    const request = await get(`/users/getAccessibleMenuItems`);
    if (request.success) {
      setAccountType(request.result.accountType);
      setAccessibleProducts(request.result.accessibleProducts);
      setAcessibleAccounts(request.result.accessibleAccounts);
    }
    if (request.error) setServerError(`Could not get accessible account list: ${request.error}. If the problem persists please contact hello@catalist.co.nz`);
  }

  const switchAccount = async (accountPublicID) => {
    setServerError();
    const request = await post('/users/switchAccount',  { accountPublicID });
    if(request.success) {
      if(router.asPath.includes('/dashboard')) window.location.href = '/dashboard'; // Reload dashboard from top level.
      else setTimeout(() => window.location.reload()); //Reload page
    } 
    if(request.error) setServerError(`Could not get accessible account list: ${request.error}. If the problem persists please contact hello@catalist.co.nz`);
  };

  useEffect(() => {
    if (props.authenticated) {
      getAccessibleMenuItems();
    }
  }, [props.authenticated]);


  // Set Desktop menu theme
  const whiteTheme = {
    // white background dark text
    menuBackgroundColor: 'white',
    catalistLogo: 'https://cdn.catalist.co.nz/v2/Catalist_Logo_Blue.png',
    classLinkText: 'text-primary-blue text-bold text-normal-size',
    classlinkTextHover: `${styles['appbar__desktop-hover']} text-secondary-blue text-bold text-normal-size`
  };

  const blueTheme = {
    // dark background white text
    menuBackgroundColor: '#0d2d50',
    catalistLogo: 'https://cdn.catalist.co.nz/v2/Catalist_Logo_White.png',
    classLinkText: 'txt-white-inverse',
    classlinkTextHover: `${styles['appbar__desktop-hover-inverse']} txt-white`,

    // optional flag so we know to style overlays in white
    isDarkBackground: true,
  };

  //... add more Desktop menu themes here.

  const greyTheme = {
    menuBackgroundColor: '#f6f6f6',
    catalistLogo: 'https://cdn.catalist.co.nz/v2/Catalist_Logo_Blue.png',
    classLinkText: 'text-primary-blue text-bold text-normal-size',
    classlinkTextHover: `${styles['appbar__desktop-hover']} text-secondary-blue text-bold text-normal-size`
  }

  let menuTheme;

  // default
  if(!props.appBarBackground) menuTheme = whiteTheme;

  if(props.appBarBackground) {
    if(props.appBarBackground === 'WHITE') menuTheme = whiteTheme;
    if(props.appBarBackground === 'BLUE') menuTheme = blueTheme;
    if(props.appBarBackground === 'GREY') menuTheme = greyTheme;
  }

  const login = () => {
    let path = router.asPath;

    if(!(path.startsWith('/marketplace') || path.startsWith('/businesses/'))) {
      path = '/dashboard';
    }

    const redirect = '/api/login?redirect=' + encodeURIComponent(path);
    window.location = redirect;
  };

  return(
    <Menu
      stackable 
      borderless 
      size="tiny" 
      style={{ marginTop: 0, marginBottom: 0, backgroundColor: `${menuTheme.menuBackgroundColor}`, 'boxShadow': 'none', 'borderBottom': '0 none' }}>
      <Menu.Item>
        <a className="item" href="/">
          <Image src={menuTheme.catalistLogo} size="small" />
        </a>
      </Menu.Item>
      <Menu.Item style={{ marginLeft: 'auto' }}></Menu.Item>

      {/* If logged in */}
      {props.authenticated && (
        <>
          {accessibleAccounts.length > 1 && 
            <Menu.Item>
              <Dropdown inline item simple text={
                <b className={`${menuTheme.classlinkTextHover} ${styles["core__paragraph"]}`} style={{ margin: 0, textOverflow: 'ellipsis' }}>
                  <Icon name='user'/>{first(accessibleAccounts.filter((a) => a.isLoggedInAccount))?.accountName}
                </b>
              }>
                <Dropdown.Menu>
                  {accessibleAccounts.map((account, index) => {
                    return (
                      <Dropdown.Item
                        key={index}
                        active={account.isLoggedInAccount} 
                        onClick={() => switchAccount(account.accountPublicID)}
                      >
                        <p style={{fontSize: '16px'}}>{account.accountName}</p>
                      </Dropdown.Item>
                    )
                  })}
                </Dropdown.Menu>
              </Dropdown>
            </Menu.Item>
          }

          <Menu.Item>
            {isEmpty(DashboardMenuOptions(accountType)) ?
              <b>
                <a href="/dashboard" className={ `${(activeLink.includes('/dashboard') && !activeLink.includes('?origin=')) ? menuTheme.classLinkText : menuTheme.classlinkTextHover} ${styles["core__paragraph"]}` }>
                  Dashboard
                </a>
              </b>
            :
              <Dropdown inline item simple text={
                <b>
                  <a href="/dashboard" className={ `${(activeLink.includes('/dashboard') && !activeLink.includes('?origin=')) ? menuTheme.classLinkText : menuTheme.classlinkTextHover} ${styles["core__paragraph"]}` }>
                    Dashboard
                  </a>
                </b>
              }>
                <Dropdown.Menu>
                  {DashboardMenuOptions(accountType).map((option, index) => {
                    return (
                      <Dropdown.Item
                        key={index}
                        active={activeLink.includes(option.url)}
                        onClick={() => {window.location = option.url}}
                      >
                        <p style={{fontSize: '16px'}}>{option.text}</p>
                      </Dropdown.Item>
                    )
                  }).concat(accessibleProducts.map((product, i) => {
                    return (
                      <Dropdown.Item
                        key={i}
                        active={activeLink.includes(`/dashboard/${product.productPublicID}`)}
                        onClick={() => {window.location = `/dashboard/${product.productPublicID}`}}
                      >
                        <span style={{fontSize: '16px'}}><Icon name='table'/>Register: <i>{product.productName}</i></span>
                        <Dropdown.Menu>
                          <Dropdown.Item
                            active={activeLink.includes(`/dashboard/${product.productPublicID}?tab=register`)}
                            onClick={(e) => { e.stopPropagation(); window.location = `/dashboard/${product.productPublicID}?tab=register`}}
                          >
                            <p style={{fontSize: '16px'}}>Register</p>
                          </Dropdown.Item>
                          <Dropdown.Item
                            active={activeLink.includes(`/dashboard/${product.productPublicID}?tab=transaction`)}
                            onClick={(e) => { e.stopPropagation(); window.location = `/dashboard/${product.productPublicID}?tab=transaction`}}
                          >
                            <p style={{fontSize: '16px'}}>Transactions</p>
                          </Dropdown.Item>
                          <Dropdown.Item
                            active={activeLink.includes(`/dashboard/${product.productPublicID}?tab=valuations`)}
                            onClick={(e) => { e.stopPropagation(); window.location = `/dashboard/${product.productPublicID}?tab=valuations`}}
                          >
                            <p style={{fontSize: '16px'}}>Valuations</p>
                          </Dropdown.Item>
                          <Dropdown.Item
                            active={activeLink.includes(`/dashboard/${product.productPublicID}?tab=auctions`)}
                            onClick={(e) => { e.stopPropagation(); window.location = `/dashboard/${product.productPublicID}?tab=auctions`}}
                          >
                            <p style={{fontSize: '16px'}}>Auctions</p>
                          </Dropdown.Item>
                          {product.distributionEnabled && 
                            <Dropdown.Item
                              active={activeLink.includes(`/dashboard/${product.productPublicID}?tab=cashflow-events`)}
                              onClick={(e) => { e.stopPropagation(); window.location = `/dashboard/${product.productPublicID}?tab=cashflow-events`}}
                            >
                              <p style={{fontSize: '16px'}}>Calls & Distributions</p>
                            </Dropdown.Item>
                          }
                        </Dropdown.Menu>
                      </Dropdown.Item>
                    )}
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            }
          </Menu.Item>
        </>
      )}

      <Menu.Item>
        {isEmpty(accessibleProducts) ?
          <b>
            <a href="/marketplace" className={ `${(activeLink.includes('/marketplace') || activeLink.includes('/businesses/')) ? menuTheme.classLinkText : menuTheme.classlinkTextHover} ${styles["core__paragraph"]}`}>
              Marketplace
            </a>
          </b>
        :
          <Dropdown inline item simple text={
            <b>
              <a href="/marketplace" className={ `${(activeLink.includes('/marketplace') || activeLink.includes('/businesses/')) ? menuTheme.classLinkText : menuTheme.classlinkTextHover} ${styles["core__paragraph"]}`}>
                Marketplace
              </a>
            </b>
          }>
            <Dropdown.Menu>
              <Dropdown.Item
                active={activeLink.includes(`/marketplace`)}
                onClick={() => {window.location = '/marketplace'}}
              >
                <p style={{fontSize: '16px'}}>All marketplace</p>
              </Dropdown.Item>
              {accessibleProducts.map((product, index) => {
                return (
                  <Dropdown.Item
                    key={index}
                    active={activeLink.includes(`/businesses/${product.productPublicID}`)}
                    onClick={() => {window.location = `/businesses/${product.productPublicID}`}}
                  >
                    <p style={{fontSize: '16px'}}>My page: {product.productName}</p>
                  </Dropdown.Item>
                )}
              )}
            </Dropdown.Menu>
          </Dropdown>
        }
      </Menu.Item>

      {!props.authenticated && 
        <>
          <Menu.Item>
            <b>
              <a href="/investors" className={ `${activeLink.includes('/investors') ? menuTheme.classLinkText : menuTheme.classlinkTextHover} ${styles["core__paragraph"]}`}>
                Investors
              </a>
            </b>
          </Menu.Item>

          <Menu.Item>
            <Dropdown inline item simple text={
              <b>
                <a href="/business-solutions" className={ `${(activeLink.includes('/business-solutions') || (activeLink === '/business') || activeLink.includes('/funds') || activeLink.includes('/investment-groups') || activeLink.includes('/advisers') || activeLink.includes('/nominee-services')) ? menuTheme.classLinkText : menuTheme.classlinkTextHover} ${styles["core__paragraph"]}` }>
                  Business solutions
                </a>
              </b>
            }>
              <Dropdown.Menu> 
               {BusinessSolutionsMenuOptions.map((option, index) => {
                  return (
                    <Dropdown.Item
                      key={index}
                      active={activeLink === (option.url)}
                      onClick={() => {window.location = option.url}}
                    >
                      <p style={{fontSize: '16px'}}>{option.text}</p>
                    </Dropdown.Item>
                  );
                })} 
              </Dropdown.Menu>
            </Dropdown>
          </Menu.Item>   

        </> 
      }

      {props.authenticated && (
        <>          
          {/* Logged in: My account and Logout buttons */}
          <Menu.Item>
            <Button href="/users/profile" color="blue" size="large">
              <div className={`${styles["core__paragraph"]} ${styles["appbar__button"]}`}>My account</div>
            </Button>
          </Menu.Item>
          <Menu.Item>
            <Button
              inverted={menuTheme.isDarkBackground} 
              color={menuTheme.isDarkBackground ? '': 'blue'} 
              basic={!menuTheme.isDarkBackground} 
              onClick={() => logout()} size="large">
                <div className={`${styles["core__paragraph"]} ${styles["appbar__button"]}`}>Logout</div>
            </Button>
          </Menu.Item>
        </>
      )}

      {/* Not logged in: Join and Login buttons */}
      {!props.authenticated && !router.asPath.startsWith('/signup/product') && (
        <>
          <Menu.Item>
            <Button 
              inverted={menuTheme.isDarkBackground} 
              color={menuTheme.isDarkBackground ? '': 'blue'} 
              basic={!menuTheme.isDarkBackground} 
              onClick={()=> {handleSidebarConfigChange({open: true, type: getSignupType()});}}
              size="large">
                <div className={`${styles["core__paragraph"]} ${styles["appbar__button"]}`}>Join</div>
            </Button>
          </Menu.Item>
          <Menu.Item>
            <Button color="blue"  onClick={() => login()} size="large">
              <div className={`${styles["core__paragraph"]} ${styles["appbar__button"]}`}>Log in</div>
            </Button>
          </Menu.Item>
        </>
      )}
    </Menu>
  );
}


/**
 * 
 * AppBar exportable
 * 
 * Sidebar component wraps desktop and mobile
 */

export default AppBar;

function AppBar(props) {

  const {sidebarConfig, handleSidebarConfigChange} = useContext(AppContext);

  const router = useRouter();
  const [activeLink, setActiveLink] = useState('');
  const [accessibleAccounts, setAcessibleAccounts] = useState([]);
  const [serverError, setServerError] = useState('');

  useEffect(() => {
    if (router != null) {
      setActiveLink(router.asPath);
    }
  }, [router]);
  
  const login = () => {
    let path = router.asPath;

    if (!(path === '/marketplace' || path.startsWith('/businesses/'))) {
      path = '/dashboard';
    }

    const redirect = '/api/login?redirect=' + encodeURIComponent(path);
    window.location = redirect;
  };

  const getLinkStyle = (linkText) => activeLink === `/${linkText}` ? 'appbar__menu__item__active' : 'appbar__menu__item';


  const getAccessibleMenuItems = async () => {
    setServerError();
    const request = await get(`/users/getAccessibleMenuItems`);
    if (request.success) setAcessibleAccounts(request.result.accessibleAccounts);
    if (request.error) setServerError(`Could not get accessible account list: ${request.error}. If the problem persists please contact hello@catalist.co.nz`);
  }

  const switchAccount = async (accountPublicID) => {
    setServerError();
    const request = await post('/users/switchAccount',  { accountPublicID });
    if(request.success) {
      if(router.asPath.includes('/dashboard')) window.location.href = '/dashboard'; // Reload dashboard from top level.
      else setTimeout(() => window.location.reload()); //Reload page
    } 
    if(request.error) setServerError(`Could not get accessible account list: ${request.error}. If the problem persists please contact hello@catalist.co.nz`);
  };

  useEffect(() => {
    if (props.authenticated) {
      getAccessibleMenuItems();
    }
  }, [props.authenticated]);

  // Set Mobile menu theme
  const whiteTheme = {
    menuBackgroundColor: 'white',
    catalistLogo: 'https://cdn.catalist.co.nz/v2/Catalist_Logo_Blue.png',
  };

  const blueTheme = {
    menuBackgroundColor: '#0d2d50',
    catalistLogo: 'https://cdn.catalist.co.nz/v2/Catalist_Logo_White.png',
    
    // optional flag so we know to style overlays in white
    isDarkBackground: true
  };

  //... add more Mobile menu themes here.

  const greyTheme = {
    menuBackgroundColor: '#f6f6f6',
    catalistLogo: 'https://cdn.catalist.co.nz/v2/Catalist_Logo_Blue.png',
  }

  let menuTheme;

  // default
  if(!props.appBarBackground) menuTheme = whiteTheme;

  if(props.appBarBackground){
    if(props.appBarBackground === 'WHITE') menuTheme = whiteTheme;
    if(props.appBarBackground === 'BLUE') menuTheme = blueTheme;
    if(props.appBarBackground === 'GREY') menuTheme = greyTheme;
  }

  const sidebarBarsColor = menuTheme.isDarkBackground ? 'white' : '#0d2d50';
  const sidebarPusherClass = sidebarConfig.animationOpenComplete ? 'appbar__pusher__expanded' : 'appbar__pusher';
  
  const sidebarOpts = sidebarConfig.type === 'SIGNUP_INVESTOR' ? 'appbar__sidebar__signupopts' : 'appbar__sidebar__defaultopts';

  const MobileMenuSideBar = ({}) => (
    <>
      {/* Main links and buttons */}
      <div className={styles["appbar__mainlinks__container"]}>

        {/* Logo and close button */}
        <Menu.Item as='a' className={`${styles['appbar__menu__item__container']} ${styles['appbar__menu__header']}`} onClick={() => handleSidebarConfigChange({open: false})}>
          <Image src={"https://cdn.catalist.co.nz/v2/Catalist_Logo_Blue.png"} className={styles["appbar__sidebar__image"]}/>
          <div className={styles["appbar__sidebar__icon__container"]}>
            <Icon name={'cancel'} size={'large'} className={styles["appbar__sidebar__icon"]}/>
          </div>
        </Menu.Item>

        {/* Menu links */}
        {/* If not auth show Join and Login */}
        {!props.authenticated && (
          <>
            {/* Join button */}
            <div className={styles['appbar__sidebar__join__btn']}>
              <Button 
                color={'blue'}
                basic
                size="large"
                onClick={()=> {handleSidebarConfigChange({type: getSignupType()})}}
                style={{width: '100%'}}>
                  <div className={`${styles["core__paragraph"]} ${styles["appbar__button"]}`}>Join</div>
              </Button>
            </div>

            {/* Login button */}
            <div className={styles['appbar__sidebar__login__btn']}>
              <Button 
                color={'blue'}
                size="large"
                onClick={()=> {handleSidebarConfigChange({open: false}); setTimeout(()=>login(), 600)}}
                style={{width: '100%'}}>
                  <div className={`${styles["core__paragraph"]} ${styles["appbar__button"]}`}>Log in</div>
              </Button>
            </div>
          </>
        )}

        {/* Auth show My account and Logout */}
        {props.authenticated && (
          <div className='pad-bottom-30px'>
            {/* My account button */}
            <div className={styles['appbar__sidebar__join__btn']}>
              <Button 
                color={'blue'}
                href="/users/profile"
                size="large"
                style={{width: '100%'}}>
                  <div className={`${styles["core__paragraph"]} ${styles["appbar__button"]}`}>My account</div>
              </Button>
            </div>

            {/* Logout button */}
            <div className={styles['appbar__sidebar__login__btn']}>
              <Button 
                color={'blue'}
                basic
                size="large"
                onClick={()=> {handleSidebarConfigChange({open: false}); setTimeout(()=>logout(), 600)}}
                style={{width: '100%'}}>
                  <div className={`${styles["core__paragraph"]} ${styles["appbar__button"]}`}>Logout</div>
              </Button>
            </div>
          </div>
        )}



        {/* If logged in */}
        { props.authenticated && (
          <>
            {accessibleAccounts.length > 1 && 
              <Menu.Item fitted>
                <Dropdown inline item text={
                  <p className={`${menuTheme.classlinkTextHover} ${styles["core__paragraph"]}`} style={{ margin: 0, textOverflow: 'ellipsis' }}>
                    <Icon name='user'/>{first(accessibleAccounts.filter((a) => a.isLoggedInAccount))?.accountName}
                  </p>
                }>
                  <Dropdown.Menu direction='left' scrolling>
                    {accessibleAccounts.map((account, index) => {
                      return (
                        <Dropdown.Item
                          key={index}
                          active={account.isLoggedInAccount} 
                          onClick={() => switchAccount(account.accountPublicID)}
                        >
                          <p style={{fontSize: '12px'}}>{account.accountName}</p>
                        </Dropdown.Item>
                      )
                    })}
                  </Dropdown.Menu>
                </Dropdown>
              </Menu.Item>
            }

            <Menu.Item className={`${styles['appbar__menu__item__container']}`}>
              <a href="/dashboard">
                <h2 className={styles[getLinkStyle('dashboard')]}>
                  Dashboard
                </h2>
              </a>
            </Menu.Item>
          </>
        )}

        <Menu.Item className={`${styles['appbar__menu__item__container']}`}>
          <a href="/marketplace">
            <h2 className={styles[getLinkStyle('marketplace')]}>
              Marketplace
            </h2>
          </a>
        </Menu.Item>
        {!props.authenticated &&
          <>
            <Menu.Item className={`${styles['appbar__menu__item__container']}`}>
              <a href="/investors">
                <h2 className={styles[getLinkStyle('Invest')]}>
                Investors
                </h2>
              </a>
            </Menu.Item>
            <Menu.Item className={`${styles['appbar__menu__item__container']}`}>
              <a href="/business-solutions">
                <h2 className={styles[getLinkStyle('List')]}>
                  Business solutions
                </h2>
              </a>
            </Menu.Item>
            <Menu.Item className={`${styles['appbar__menu__item__container']}`}>
              <a href="/About">
                <h2 className={styles[getLinkStyle('/About')]}>
                About
                </h2>
              </a>
            </Menu.Item>
            <Menu.Item className={`${styles['appbar__menu__item__container']}`}>
              <a href="/Contact">
                <h2 className={styles[getLinkStyle('/Contact')]}>
                Contact
                </h2>
              </a>
            </Menu.Item>
          </>
        }
      </div>

      {/* Small links and copyright */}
      <div className={styles["appbar__smalllinks__container"]}>
        {props.authenticated && 
          <>
            <div className={`pad-top-10px ${styles["core__paragraph"]}`}>
              <a href="/investors" 
                target="_blank" >
                 Investors
              </a>
            </div>
            <div className={`pad-top-10px ${styles["core__paragraph"]}`}>
              <a href="/business-solutions" 
                target="_blank" >
                 Business solutions
              </a>
            </div>
            <div className={`pad-top-10px ${styles["core__paragraph"]}`}>
              <a href="/About" 
                target="_blank" >
                 About
              </a>
            </div>
            <div className={`pad-top-10px ${styles["core__paragraph"]}`}>
              <a href="/Contact" 
                target="_blank" >
                 Contact
              </a>
            </div>
          </>
        }
        <div className={`pad-top-10px ${styles["core__paragraph"]}`}>
          <a href="/MarketplaceMechanics" 
            target="_blank" >
            Marketplace mechanics
          </a>
        </div>
        <div className={`pad-top-10px ${styles["core__paragraph"]}`}>
          <a href="/info/blog">
            Blog
          </a>
        </div>
       
        <div className={`pad-top-10px ${styles["core__paragraph"]}`}>
          <a href="/FAQ">
            FAQ
          </a>
        </div>
        <div className={`pad-top-10px ${styles["core__paragraph"]}`}>
          <a href="/Fees">
            Fees
          </a>
        </div>
        <div className={styles["core__paragraph"]} style={{paddingTop: '1rem', paddingBottom: '1rem'}}>©&nbsp; Catalist Markets Limited {(new Date()).getFullYear()}.<br/> All rights reserved.</div>
      </div>


      {/* Sidebar footer */}
      <div className={styles["appbar__sidebar__footer"]}>
        <a target="_blank" href="https://www.linkedin.com/company/catalistmarkets/" rel="noopener">
          <Icon name="linkedin" size="large" style={{ color: 'white'}}/>
        </a>
        <a target="_blank" href='https://www.facebook.com/CatalistMarkets' rel="noopener">
          <Icon name="facebook f" size="large" style={{ color: 'white'}}/>
        </a>
        <a target="_blank" href='https://www.instagram.com/catalistmarkets' rel="noopener">
          <Icon name="instagram" size="large" style={{ color: 'white'}}/>
        </a>
      </div>
    </>
  )

  return (
    <>
      <Sidebar.Pushable>
        <Sidebar
          as={Menu}
          animation='overlay'
          // start of close animation
          onHide={() => handleSidebarConfigChange({open: false})}
          // end of close animation
          // set sidebar type back to mobile menu
          onHidden={() => {handleSidebarConfigChange({type: 'MOBILE_MENU', animationOpenComplete: false});}}
          vertical
          visible={sidebarConfig.open}
          direction='right'
          className={`${styles["appbar__sidebar"]} ${styles[sidebarOpts]}`}>

            { (sidebarConfig.type === 'MOBILE_MENU') && <MobileMenuSideBar/> }

            { (sidebarConfig.type === 'SIGNUP_INVESTOR') && <InvestorSignUp handleSidebarConfigChange={handleSidebarConfigChange} /> }

            { (sidebarConfig.type === 'SIGNUP_INVESTOR_KEY_PERSON') && 
              <InvestorSignUpKeyPerson handleSidebarConfigChange={handleSidebarConfigChange}/> }

            { (sidebarConfig.type === 'SIGNUP_INVESTOR_JOIN_GROUP') && 
              <InvestorSignUpJoinGroup handleSidebarConfigChange={handleSidebarConfigChange}/> }

            {/* Add new sidebar 'pages' here: */}
            {/* <MenuComponent handleSidebarConfigChange={handleSidebarConfigChange} > (prop is to close) */}
            {/* use new and set sidebarOpts for styling */}
            {/* open sidebar item globally using import SidebarOpener component */}
        </Sidebar>
        

        {/* Sidebar work around: Issue - pusher covers all content preventing button clicks */}
        {/* Fix - use sidebarPusherClass to set pusher height on open/close */}
        <Sidebar.Pusher dimmed={sidebarConfig.animationOpenComplete} className={styles[sidebarPusherClass]}>
          {/* Use calculated values for styles */}
          <div className={styles['appbar__container__mobile']} style={{backgroundColor: `${menuTheme.menuBackgroundColor}`}}>
            <a href="/">
              <Image src={menuTheme.catalistLogo} size="small" className={styles["appbar__desktop__logo"]}/>
            </a>
            <Icon name="bars" 
              size="big" 
              style={{ color: `${sidebarBarsColor}`}} 
              onClick={() => handleSidebarConfigChange({open: true, type: 'MOBILE_MENU'}) } 
              className={styles["appbar__bars"]}/>
          </div>

          {sidebarConfig.open && /* If the sidebar is open we render the header inside the sidebar so it doesn't disappear when the sidebar is open. */
            <div className={styles['appbar__desktop']}>
              <DesktopMenu {...props} />
            </div>
          }
        </Sidebar.Pusher>
      </Sidebar.Pushable>

      {!sidebarConfig.open && /* When the sidebar is closed we render the header menu outside the sidebar so it allows menu dropdowns to overflow outside the container. */
        <div className={styles['appbar__desktop']}>
          <DesktopMenu {...props} />
        </div>
      }
    </>
  );
}


function DashboardMenuOptions(accountType) {
  if(accountType === 'angel') {
    return [
      {text: 'Portfolio', url: '/dashboard?tab=portfolio'},
      {text: 'Member register', url: '/dashboard?tab=member-register'},
      {text: 'Investor groups', url: '/dashboard?tab=investor-groups'},
      {text: 'Communications', url: '/dashboard?tab=communication-notices'},
      {text: 'Orders / Trading', url: '/dashboard?tab=orders'},
      {text: 'Transactions', url: '/dashboard?tab=transaction'},
    ];
  }

  if(accountType === 'issuer') {
    return [
      {text: 'Overview', url: '/dashboard?tab=overview'},
      {text: 'Documents', url: '/dashboard?tab=documents'},
      {text: 'Investor groups', url: '/dashboard?tab=investor-groups'},
      {text: 'Communications', url: '/dashboard?tab=communication-notices'},
    ];
  }

  if(
    accountType === 'individual_investor' ||
    accountType === 'joint_investor' || 
    accountType === 'trust_investor' ||
    accountType === 'company_investor' ||
    accountType === 'legal_entity_investor'
  ) {
    return [
      {text: 'Portfolio', url: '/dashboard?tab=portfolio'},
      {text: 'Orders', url: '/dashboard?tab=orders'},
      {text: 'Transactions', url: '/dashboard?tab=transaction'},
    ];
  }

  if(
    accountType === 'individual_investor_sub_holding' ||
    accountType === 'joint_investor_sub_holding' || 
    accountType === 'trust_investor_sub_holding' ||
    accountType === 'company_investor_sub_holding' ||
    accountType === 'legal_entity_investor_sub_holding' ||
    accountType === 'broker'
  ) {
    return [
      {text: 'Portfolio', url: '/dashboard?tab=portfolio'},
      {text: 'Register', url: '/dashboard?tab=register'},
      {text: 'Orders', url: '/dashboard?tab=orders'},
      {text: 'Transactions', url: '/dashboard?tab=transaction'},
    ];
  }
  else return [];
}

const BusinessSolutionsMenuOptions =  [
    {text: 'Business solutions', url: '/business-solutions'},
    {text: 'For businesses', url: '/business'},
    {text: 'For funds', url: '/funds'},
    {text: 'For investment groups', url: '/investment-groups'},
    {text: 'For advisers', url: '/advisers'},
    {text: 'Nominee company services', url: '/nominee-services'},
  ]